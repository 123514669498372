<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import dailyTransactions from "../../../mixins/dailyCalculationsMixin";
import io from "socket.io-client";

/**
 * Dashboard component
 */
export default {
  components: {
    Layout,
    PageHeader,
  },
  mixins: [dailyTransactions],
  data() {
    return {
      title: "Daily Agent Report",
      stickyHeader: true,
      noCollapse: false,
      items: [
        {
          text: "Admin",
        },
        {
          text: "Daily Agent Report",
          active: true,
        },
      ],
    };
  },
  methods: {
    agentTransaction(id) {
      this.$router.push(`/admin/agent-transactions/${id}`);
    },
  },
  computed: {

  },

  mounted() {
    const socket = io(process.env.VUE_APP_SOCKET_URL);

    // WebSocket event handlers
    socket.on("makeDeposit", (newData) => {
      console.log(newData);
      this.todaysTransactions = [newData, ...this.todaysTransactions];
    });

    socket.on("withdrawal", (newData) => {
      console.log(newData);
      this.todaysTransactions = [newData, ...this.todaysTransactions];
    });

    socket.on("loanRepayment", (newData) => {
      console.log(newData);
      this.todaysTransactions = [newData, ...this.todaysTransactions];
      console.log(this.todaysTransactions);
    });

    this.getTodaysTransactions();
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row" v-if="loading">
      <b-spinner class="m-2 m-auto" variant="primary" role="status"></b-spinner>
    </div>
    <div v-else>
      <div class="row justify-content-center align-items-center">
        <div class="col-md-2 text-center">
          <div class="card">
            <div class="card-body">
              <div class="media">
                <div class="media-body">
                  <strong class="text-truncate font-size-18 mb-2">
                    TOTAL SALES
                  </strong>
                  <h2 class="mb-0">
                    <span class="badge badge-success font-size-24">
                      GHS {{ calculateTransactions.toLocaleString() }}
                    </span>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-2 text-center">
          <div class="card">
            <div class="card-body">
              <div class="media">
                <div class="media-body">
                  <strong class="text-truncate font-size-18 mb-2 uppercase">
                    MOMO SALES
                  </strong>
                  <h2 class="mb-0">
                    <span class="badge badge-success font-size-24">
                      GHS {{ momoTransactions.toLocaleString() }}
                    </span>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-2 text-center">
          <div class="card">
            <div class="card-body">
              <div class="media">
                <div class="media-body">
                  <strong class="text-truncate font-size-18 mb-2 uppercase">
                    CASH SALES
                  </strong>
                  <h2 class="mb-0">
                    <span class="badge badge-success font-size-24">
                      GHS {{ cashTransactions.toLocaleString() }}
                    </span>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-2 text-center">
          <div class="card">
            <div class="card-body">
              <div class="media">
                <div class="media-body">
                  <strong class="text-truncate font-size-18 mb-2 uppercase">
                    WITHDRAWALS
                  </strong>
                  <h2 class="mb-0">
                    <span class="badge badge-success font-size-24">
                      GHS {{ cashWithdrawals.toLocaleString() }}
                    </span>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-2 text-center">
          <div class="card">
            <div class="card-body">
              <div class="media">
                <div class="media-body">
                  <strong class="text-truncate font-size-18 mb-2 uppercase">
                    CASH COLLATERALS
                  </strong>
                  <h2 class="mb-0">
                    <span class="badge badge-success font-size-24">
                      GHS {{ cashCollaterals.toLocaleString() }}
                    </span>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div
          class="col-md-3 custom-cursor"
          v-for="agent in agentSummed"
          :key="agent.agentId"
          @click.prevent="agentTransaction(agent.agentId)"
        >
          <div class="card">
            <div class="card-body">
              <div class="media">
                <div class="media-body overflow-hidden">
                  <p class="text-truncate font-size-18 mb-2">
                    {{ agent.agentName }}
                  </p>
                  <h2 class="mb-0">
                    GHS {{ agent.totalAmount.toLocaleString() }}
                  </h2>
                </div>
                <div class="text-primary">
                  <i class="ri-user-2-line font-size-24"></i>
                </div>
              </div>
            </div>

            <div class="card-body border-top py-3">
              <div class="text-truncate">
                <span class="badge badge-soft-success font-size-11">
                  <i class="mdi mdi-menu-up"></i>
                  {{ agent.totalAmount }}
                </span>
                <span class="text-muted ml-2">{{ agent.agentName }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.custom-table-container {
  font-size: 1.2rem; /* Increase font size */
}
.custom-cursor {
  cursor: pointer;
}
</style>
